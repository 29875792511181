import React from "react"
import { Container, Main, Seo } from "../components"
import { H1 } from "../elements"

const notFoundPage = ({ data, location }) => {
  return (
    <Container>
      <Seo pagetitle="ページが見つかりません" pagepath={location.pathname} />
      <Main>
        <H1
          style={{ padding: "20vh 0", fontSize: "1.5rem" }}
          textAlign="center"
        >
          お探しのページが見つかりませんでした。
        </H1>
      </Main>
    </Container>
  )
}

export default notFoundPage
